<template>
  <div class="elv-rule-form-valuation-supplementary">
    <el-form-item :label="t('common.table')">
      <SingleChoiceSelect
        v-model="derivativeType"
        filterable
        :placeholder="`${t('common.select')}...`"
        width="802px"
        :options="derivativeTypeOptions"
        :popper-append-to-body="false"
        @change="onChangeDerivativeType"
      />
    </el-form-item>

    <div class="elv-rule-form-valuation-supplementary__multiple">
      <el-form-item :label="t('common.origin')">
        <el-select
          v-model="origin"
          :placeholder="`${t('common.select')}...`"
          class="is-shorter"
          :disabled="true"
          :suffix-icon="SelectSuffixIcon"
        >
          <el-option label="Client" value="CLIENT" />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('common.dateTime')">
        <SingleChoiceSelect
          v-model="action.datetime"
          :placeholder="`${t('common.select')}...`"
          width="262px"
          filterable
          :options="props.columnOptions"
          :popper-append-to-body="false"
        />
      </el-form-item>
      <el-form-item :label="t('common.timezone')">
        <el-select
          v-model="action.timezone"
          filterable
          default-first-option
          :placeholder="`${t('common.select')}...`"
          class="is-shorter"
          :suffix-icon="SelectSuffixIcon"
        >
          <el-option
            v-for="(item, index) in timezoneList"
            :key="index"
            :label="`${item.area} (${item.timezone})`"
            :value="item.area"
          />
        </el-select>
      </el-form-item>
      <el-form-item :label="t('report.referenceNo')">
        <SingleChoiceSelect
          v-model="action.referenceNo"
          width="262px"
          filterable
          :placeholder="`${t('common.select')}...`"
          :options="props.columnOptions"
          :popper-append-to-body="false"
        />
      </el-form-item>
      <el-form-item :label="t('common.type')">
        <SingleChoiceSelect
          v-model="action.type"
          class="is-special-select"
          width="262px"
          :placeholder="`${t('common.select')}...`"
          :options="typeOptions"
          :popper-append-to-body="false"
        />
      </el-form-item>
      <el-form-item :label="t('common.symbol')">
        <SingleChoiceSelect
          v-model="action.assetName"
          width="262px"
          filterable
          :placeholder="`${t('common.select')}...`"
          :options="props.columnOptions"
          :popper-append-to-body="false"
        />
      </el-form-item>
      <el-form-item v-if="tableType === 'FUTURE'" :label="t('report.positionAndSide')">
        <SingleChoiceSelect
          v-model="action.positionSide"
          width="262px"
          :placeholder="`${t('common.select')}...`"
          :options="props.columnOptions"
          :popper-append-to-body="false"
        />
      </el-form-item>
      <el-form-item
        v-if="tableType"
        :label="tableType === 'FUTURE' ? t('valuation.assets.marginAsset') : t('valuation.assets.quotaAsset')"
      >
        <SingleChoiceSelect
          v-model="action.currency"
          width="262px"
          :placeholder="`${t('common.select')}...`"
          :options="props.columnOptions"
          :popper-append-to-body="false"
        />
      </el-form-item>
      <el-form-item label="P & L">
        <SingleChoiceSelect
          v-model="action.amount"
          width="262px"
          filterable
          :placeholder="`${t('common.select')}...`"
          :options="props.columnOptions"
          :popper-append-to-body="false"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script setup lang="ts">
import { $t } from '@/i18n'
import timezoneList from '@/config/timezone'
import { gainLossType } from '@/config/index'
import SelectSuffixIcon from '@/components/Project/SelectSuffixIcon.vue'
import SingleChoiceSelect from '@/components/Base/SingleChoiceSelect.vue'

const { t } = useI18n()

const props = defineProps({
  columnOptions: {
    type: Array as () => ElvSelectOptionType[],
    required: true
  }
})

const derivativeTypeOptions = reactive([
  { value: 'FUTURE', label: $t('automation.futureDerivativeTypeRawData') },
  { value: 'OPTION', label: $t('automation.optionDerivativeTypeRawData') }
])

const derivativeType = defineModel<string>('derivativeType', { required: true })
const action = defineModel<any>('action', { required: true })

const origin = ref('CLIENT')

const tableType = computed(() => {
  if (!derivativeType.value && !action.value?.type) return ''
  return derivativeType.value === 'FUTURE' || ['FUTURE_UNREALIZED', 'FUTURE_REALIZED'].includes(action.value?.type)
    ? 'FUTURE'
    : 'OPTION'
})

const typeOptions = computed(() => {
  switch (derivativeType.value) {
    case 'FUTURE':
      return gainLossType
        .filter((item) => item.value === 'FUTURE_UNREALIZED' || item.value === 'FUTURE_REALIZED')
        .map((item) => {
          return {
            value: item.value,
            label: item.label
          }
        })
    case 'OPTION':
      return gainLossType
        .filter((item) => item.value === 'OPTION_UNREALIZED' || item.value === 'OPTION_REALIZED')
        .map((item) => {
          return {
            value: item.value,
            label: item.label
          }
        })
    default:
      return gainLossType.map((item) => {
        return {
          value: item.value,
          label: item.label
        }
      })
  }
})

const onChangeDerivativeType = (val: any) => {
  if (val === 'OPTION' && (action.value?.type.indexOf('FUTURE') > -1 || action.value.positionSide !== '')) {
    action.value.positionSide = ''
    action.value.type = ''
  }
  if (val === 'FUTURE' && action.value?.type.indexOf('OPTION') > -1) {
    action.value.type = ''
  }
  console.log('action.value', action.value)
}
</script>

<style lang="scss">
.elv-rule-form-valuation-supplementary {
  margin-bottom: 16px;
  margin-top: 8px;

  .el-form-item {
    margin-bottom: 0px !important;

    .el-form-item__label {
      color: #636b75 !important;
      font-size: 13px !important;
      font-weight: 600 !important;

      &::after {
        display: none !important;
      }
    }

    .elv-base-single-choice-select.el-select .el-select__wrapper .el-tag {
      border-radius: 3px;
      border: 1px solid #dde1e6;
      background: #edf0f3;
      height: 28px;
      box-sizing: border-box;

      .el-tag__content {
        color: #1753eb;
        font-family: 'Barlow';
      }
    }
  }
}

.elv-rule-form-valuation-supplementary__multiple {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px 0px;
  max-width: 802px;

  .el-select__wrapper {
    width: 262px;
  }

  .el-select.elv-base-single-choice-select {
    &:not(.is-special-select) .el-select__selected-item.el-select__placeholder:not(.is-transparent) {
      span {
        border-radius: 3px;
        border: 1px solid #dde1e6;
        background: #edf0f3;
        display: flex;
        padding: 0px 8px;
        height: 28px;
        box-sizing: border-box;
        justify-content: center;
        align-items: center;
        width: fit-content;
        color: #1753eb;
        font-family: 'Barlow';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }
  }
}
</style>
