<template>
  <div class="elv-automation-rule-action-entry">
    <template v-if="!!entryLineList.length">
      <div
        v-for="(item, index) in entryLineList"
        :key="item.index"
        class="elv-automation-rule-entry-item"
        :class="[item.balanceType === 'Dr' ? 'is-Dr' : 'is-Cr']"
      >
        <div
          class="elv-automation-rule-entry-title"
          :class="{ 'is-disabled': item?.chartOfAccountCalculator === 'ADJUSTING' }"
        >
          <p>#{{ item.index + 1 }}</p>
          <span :class="{ 'is-Dr': item.balanceType === 'Dr' }">{{ item.balanceType }}</span
          >{{
            item.chartOfAccountCalculator === 'TREASURY_ACCOUNT' || props.isInternalTransfer
              ? (props.isInternalTransfer && !item?.amountFC) || !props.isInternalTransfer
                ? `{ ${t('report.treasuryAccount')} }`
                : ''
              : locale === 'en'
                ? item?.chartOfAccount?.name
                : item?.chartOfAccount?.nameCN || item?.chartOfAccount?.name
          }}
          <ElvCascade
            v-if="internalTransferAmountTolerance && item?.amountFC"
            v-model:cascadeModel="entryLineList[item.index].chartOfAccountId"
            class="elv-automation-rule-entry-item-chart-of-account-cascade"
            :enabledFilter="false"
            :isMultipleSelect="false"
            :isHighLight="false"
            selectWeight="390px"
            selectHeight="39px"
            :cascadeOptions="chartOfAccountOptions"
            cascadeOptionValueName="chartOfAccountId"
            :cascadeOptionLabelName="locale === 'en' ? 'name' : 'nameCN'"
            cascadeOptionChildName="list"
            :selectPlaceholder="t('common.select') + '...'"
            @onChangeSelectCascadeOptions="onChangeInternalChartOfAccount"
          />
        </div>
        <div
          v-if="
            !!entryLineList.length &&
            !props.loading &&
            item?.chartOfAccountCalculator !== 'ADJUSTING' &&
            showInternalTransferAuxiliaryList(item)
          "
          class="elv-automation-rule-entry-auxiliary-list"
        >
          <div v-if="isShowEntryCurrency(item.index).value" class="elv-automation-rule-entry-auxiliary-item">
            <p class="elv-automation-rule-entry-auxiliary-item__title">
              {{ t('common.currency') }}
            </p>
            <template
              v-if="!['CRYPTO_DISPOSAL', 'CRYPTO_ADJUSTING'].includes(props.derivativeType) || props.isInternalTransfer"
            >
              <el-select
                v-if="
                  currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] === 'REPORTING' ||
                  ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) ||
                  props.isInternalTransfer
                "
                :model-value="entityStore.entityDetail.defaultCurrency"
                :disabled="true"
              >
                <template #label>
                  <div class="elv-automation-rule-entry-reporting-currency">
                    <img
                      :src="entityStore.entityDetail.underlyingCurrency?.logo"
                      :alt="entityStore.entityDetail.underlyingCurrency?.name"
                    />
                    <p>{{ entityStore.entityDetail.defaultCurrency }}</p>
                  </div>
                </template>
                <el-option
                  :label="entityStore.entityDetail.defaultCurrency"
                  :value="entityStore.entityDetail.defaultCurrency"
                >
                </el-option>
              </el-select>

              <el-select
                v-else
                :model-value="entryLineList?.[item.index]?.currency"
                popper-class="elv-automation-rule-entry-auxiliary-item-popper is-not__line"
                @change="onChangeSelect('currency', item.index, $event)"
              >
                <template #label="{ label }">
                  <span>{{ label }}</span>
                </template>
                <el-option
                  v-for="currency in currencyEnumOptions"
                  :key="currency.value"
                  :label="currency.label"
                  :value="currency.value"
                >
                  {{ currency.title }}
                </el-option>
              </el-select>
            </template>

            <div v-else class="elv-automation-rule-entry-item-cascader">
              <el-cascader
                popper-class="elv-automation-rule-entry-item-cascader-popper is-filterable"
                class="el-select is-filterable"
                :show-all-levels="false"
                :disabled="
                  currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] === 'REPORTING' ||
                  ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug)
                "
                :model-value="entryLineList?.[item.index].currency"
                :options="currencyOptions(item.chartOfAccountId).value"
                @change="onChangeSelect('currency', item.index, $event)"
              >
                <template #default="{ node, data }">
                  <el-input
                    v-if="node.isLeaf && data?.disabled"
                    ref="cascaderCurrencyInputRefs"
                    v-model="searchQuery"
                    placeholder="Search"
                    class="elv-automation-rule-entry-item-cascader-search"
                    @input="remoteCurrencyMethod(item.index)"
                  >
                    <template #prefix><SvgIcon name="select-search" width="18px" height="18px" /></template>
                    <template #suffix>
                      <el-icon v-show="searchLoading" class="is-loading"> <Loading /></el-icon>
                    </template>
                  </el-input>
                  <div v-else class="elv-automation-rule-entry-item-cascader-item">
                    <img v-if="data?.logo" :src="data?.logo" :alt="data?.alt" />
                    <span>{{ data.label }}</span>
                  </div>
                </template>
              </el-cascader>

              <!-- cascader placeholder -->
              <div
                class="elv-automation-rule-entry-item-cascader__display"
                :class="{
                  'is-disabled':
                    currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] === 'REPORTING' ||
                    ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug)
                }"
              >
                <template v-if="entryLineList?.[item.index]?.currency?.length">
                  <p
                    v-if="entryLineList?.[item.index]?.currency?.[0] === 'REPORTING_CURRENCY'"
                    class="cascader-placeholder__with-icon"
                  >
                    <img
                      :src="entityStore.entityDetail.underlyingCurrency?.logo"
                      :alt="entityStore.entityDetail.underlyingCurrency?.name"
                    /><span>{{ entityStore.entityDetail.underlyingCurrency?.showSymbol }}</span>
                  </p>
                  <p
                    v-else-if="entryLineList?.[item.index]?.currency?.[0] === 'DIRECT_CURRENCY'"
                    class="cascader-placeholder__with-icon"
                  >
                    <img
                      :src="currentCurrency(entryLineList?.[item.index]?.currency?.[1]).value?.logo"
                      :alt="currentCurrency(entryLineList?.[item.index]?.currency?.[1]).value?.name"
                    />
                    <span>{{ currentCurrency(entryLineList?.[item.index]?.currency?.[1]).value?.showSymbol }}</span>
                  </p>
                  <p
                    v-else-if="entryLineList?.[item.index]?.currency?.[0] === 'DISPOSAL_CURRENCY'"
                    class="cascader-placeholder__tag"
                  >
                    {{ t('valuation.crypto.disposalCurrency') }}
                  </p>
                  <p
                    v-else-if="entryLineList?.[item.index]?.currency?.[0] === 'ADJUSTING_CURRENCY'"
                    class="cascader-placeholder__tag"
                  >
                    {{ t('valuation.crypto.adjustingCurrency') }}
                  </p>
                </template>
                <template v-else>
                  <span class="is-placeholder">Select...</span>
                </template>
              </div>
            </div>
          </div>

          <div
            v-if="
              entryLineList?.[item.index]?.amount !== undefined &&
              currentChartOfAccount(item.chartOfAccountId).value?.currencyTypes?.[0] !== 'REPORTING' &&
              !['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) &&
              !props.isInternalTransfer
            "
            class="elv-automation-rule-entry-auxiliary-item"
          >
            <p class="elv-automation-rule-entry-auxiliary-item__title">
              {{ t('common.amount') }}
            </p>
            <el-select
              :model-value="entryLineList?.[item.index]?.amount"
              popper-class="elv-automation-rule-entry-auxiliary-item-popper is-not__line"
              @change="onChangeSelect('amount', item.index, $event)"
            >
              <template #label="{ label }">
                <span>{{ label }} </span>
              </template>
              <el-option
                v-for="amount in amountOptions"
                :key="amount.value"
                :label="amount.label"
                :value="amount.value"
              >
                {{ amount.title }}
              </el-option>
            </el-select>
          </div>

          <div v-if="isShowEntryAmountFC(item.index).value" class="elv-automation-rule-entry-auxiliary-item">
            <p class="elv-automation-rule-entry-auxiliary-item__title">
              {{ t('common.amount')
              }}{{ props.isInternalTransfer ? '' : `(${entityStore.entityDetail.defaultCurrency})` }}
            </p>
            <ElvSelect
              v-if="isInternalTransfer && internalTransferAmountTolerance"
              v-model="entryLineList[item.index].amountFC"
              :options="internalTransferAmountFcOptions"
              width="256px"
              :placeholder="t('common.select') + '...'"
            />
            <div v-else class="elv-automation-rule-entry-item-cascader">
              <el-cascader
                ref="cascaderAmountFCRefs"
                popper-class="elv-automation-rule-entry-item-cascader-popper"
                class="el-select is-filterable"
                :show-all-levels="false"
                :model-value="entryLineList?.[item.index].amountFC"
                :options="amountFCOptions(item.index, item?.balanceType)"
                :disabled="
                  props.entryLines?.[item.index]?.amountFC?.[0] === 'COST_BASIS_OF_SALES' ||
                  ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) ||
                  (hasGstType && ['NA', ''].includes(entryLineList?.[item.index].gstType))
                "
                @change="onChangeSelect('amountFC', item.index, $event)"
              >
                <template #default="{ data }">
                  <div
                    class="elv-automation-rule-entry-item-cascader-item"
                    :class="{
                      'is-formula': data.value === 'FORMULA',
                      'has-line': ['AMOUNT_FC', 'FEE_AMOUNT_FC'].includes(data.value)
                    }"
                    @click="onOpenFormula('amountFC', item.index, data)"
                  >
                    <SvgIcon
                      v-if="data.value === 'FORMULA'"
                      name="function-math"
                      width="18px"
                      height="18px"
                      fill="#1753EB"
                    />
                    <img v-if="data?.logo" :src="data?.logo" :alt="data?.alt" />
                    <span>{{ data.label }}</span>
                  </div>
                </template>
              </el-cascader>
              <!-- cascader placeholder -->
              <div
                class="elv-automation-rule-entry-item-cascader__display"
                :class="{
                  'is-disabled':
                    props.entryLines?.[item.index]?.amountFC?.[0] === 'COST_BASIS_OF_SALES' ||
                    ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) ||
                    (hasGstType && ['NA', ''].includes(entryLineList?.[item.index].gstType))
                }"
              >
                <template v-if="entryLineList?.[item.index]?.amountFC?.length">
                  <p v-if="isNumber(entryLineList?.[item.index]?.amountFC?.[0])" class="cascader-placeholder__tag">
                    {{ `#${entryLineList?.[item.index]?.amountFC?.[0] + 1} Amount` }}({{
                      entityStore.entityDetail.defaultCurrency
                    }})
                  </p>

                  <div v-else-if="entryLineList?.[item.index]?.amountFC?.[0] === 'FORMULA'" class="is-formula">
                    <template
                      v-if="
                        entryLineList?.[item.index]?.amountFC?.[1] &&
                        formulaFormatter(entryLineList?.[item.index]?.amountFC?.[1]).length
                      "
                    >
                      <SvgIcon name="function-math" width="18px" height="18px" fill="#1753EB" />
                      <template
                        v-for="(formula, i) in formulaFormatter(entryLineList?.[item.index]?.amountFC?.[1])"
                        :key="i"
                      >
                        <p v-if="formula?.type !== 'operator'" class="cascader-placeholder__tag">
                          {{ formula.value }}
                        </p>
                        <span v-else class="is-operator"> {{ formula.value }}</span>
                      </template>
                    </template>
                    <span v-else class="is-placeholder">Select...</span>
                  </div>
                  <p
                    v-else-if="entryLineList?.[item.index]?.amountFC?.[0] === 'COST_BASIS_OF_SALES'"
                    class="cascader-placeholder__tag"
                  >
                    COST_BASIS_OF_SALES
                  </p>
                  <p v-else class="cascader-placeholder__tag">
                    {{
                      find(amountFCOptions(item.index, item?.balanceType), {
                        value: entryLineList?.[item.index]?.amountFC?.[0]
                      })?.title
                    }}
                  </p>
                </template>
                <div
                  v-else-if="
                    ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug) ||
                    (hasGstType && ['NA', ''].includes(entryLineList?.[item.index].gstType))
                  "
                  class="is-formula"
                >
                  <SvgIcon name="function-math" width="18px" height="18px" fill="#1753EB" />
                  <p class="cascader-placeholder__tag">
                    {{
                      gstFormulaFormat(
                        hasGstType?.gstType,
                        hasGstTypeIndex,
                        ['gst_input', 'gst_output'].includes(currentChartOfAccount(item.chartOfAccountId).value?.slug),
                        hasGstType?.gstRatio,
                        defaultCurrency
                      )
                    }}
                  </p>
                </div>
                <template v-else>
                  <span class="is-placeholder">Select...</span>
                </template>
              </div>
            </div>
          </div>

          <template v-if="isShowEntryAuxiliaryList(item.index).value">
            <div
              v-for="(auxiliary, i) in props.entryLines?.[index]?.auxiliaryTypes"
              :key="i"
              class="elv-automation-rule-entry-auxiliary-item"
            >
              <template v-if="!isEmpty(entryLineAuxiliaryValueMap?.[index]?.[i]) && auxiliary.name">
                <p class="elv-automation-rule-entry-auxiliary-item__title">
                  {{ `${t('report.auxiliaryCode')} / ${auxiliary.name}` }}
                </p>
                <el-select
                  v-model="entryLineAuxiliaryValueMap[index][i].value.type"
                  :filterable="auxiliary.type === 'COUNTERPARTY' && !props.isInternalTransfer"
                  :remote="!props.isInternalTransfer"
                  reserve-keyword
                  :remote-method="remoteCounterpartyMethod"
                  :loading="searchLoading"
                  popper-class="elv-automation-rule-entry-auxiliary-item-popper"
                >
                  <el-option
                    v-for="auxiliaryType in auxiliaryOptions(auxiliary)"
                    :key="auxiliaryType.value"
                    :label="auxiliaryType.label"
                    :value="auxiliaryType.value"
                  >
                    <div v-if="auxiliaryType?.info" class="elv-automation-auxiliary-dropdown__item-special">
                      <div>{{ auxiliaryType.label }}</div>
                      <p>{{ auxiliaryType.info }}</p>
                    </div>
                    <template v-else>
                      <img v-if="auxiliaryType.icon" :src="auxiliaryType.icon" :alt="auxiliaryType.alt" />
                      {{ auxiliaryType.label }}
                      <span v-if="auxiliaryType?.isDeleted" class="is-deleted">deleted</span>
                    </template>
                  </el-option>
                </el-select>
              </template>
            </div>
          </template>
        </div>

        <template
          v-if="
            !props.isGeneralJournalType &&
            item.gstType !== 'NA' &&
            item.gstType !== 'NONE' &&
            item.gstType !== '' &&
            entryLineList.find((i: any) => i.gstType !== 'NA')?.balanceType === item.balanceType &&
            (((item.index + 1) % 2 !== 0 && entryLines?.length !== 2) || entryLines?.length === 2) &&
            !props.isInternalTransfer
          "
        >
          <div class="elv-automation-rule-entry-item__GSTRate">
            {{ t('title.GSTRate') }}: {{ transformI18n(find(journalTypeGstOptions, { value: item.gstType })?.label)
            }}<span v-if="item.gstType !== 'EXEMPT'"
              >, {{ item.gstType !== 'ZERO' ? `${Number(item.gstRatio)}%` : '0%' }}</span
            >
          </div>
        </template>
      </div>
    </template>

    <FormulaDialog
      ref="formulaDialogRef"
      :model="formulaItem?.value ? 'edit' : 'add'"
      :formulaData="formulaItem?.value || ''"
      :parameters="amountParameters(formulaItem?.index)"
      @onSaveFormula="onSaveFormula"
    />
  </div>
</template>

<script setup lang="ts">
import ConfigApi from '@/api/ConfigApi'
import AccountsApi from '@/api/AccountsApi'
import { transformI18n } from '@/i18n/index'
import { gstFormulaFormat } from '@/lib/utils'
import { CurrencyItemType } from '#/ReportsTypes'
import FormulaDialog from '../../FormulaDialog.vue'
import ElvSelect from '@/components/Base/ElvSelect.vue'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import ElvCascade from '@/components/Base/ElvCascade.vue'
import { useAccountStore } from '@/stores/modules/accounts'
import { journalTypeGstOptions } from '@/config/reports/index'
import { find, isEmpty, uniqBy, isNumber, cloneDeep } from 'lodash-es'

const props = defineProps({
  model: {
    type: String,
    default: 'add'
  },
  journalTypeData: {
    type: Object,
    required: true
  },
  entryLines: {
    type: Array<any>,
    required: true
  },
  auxiliaryTypeList: {
    type: Array<any>,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  },
  conditionType: {
    type: String,
    default: ''
  },
  derivativeType: {
    type: String,
    default: ''
  },
  currencyList: {
    type: Array<CurrencyItemType>,
    required: true
  },
  isGeneralJournalType: {
    type: Boolean,
    required: true
  },
  isInternalTransfer: {
    type: Boolean,
    required: true
  },
  conditionList: {
    type: Array as () => any[],
    required: true
  },
  amountFormulaList: {
    type: Array as () => any[],
    required: true
  }
})

const route = useRoute()
const { t, locale } = useI18n()
const entryLineList: any = toRef(() => props.entryLines)
const entityStore = useEntityStore()
const accountStore = useAccountStore()

const entryLineAuxiliaryValueMap = defineModel<any>('entryLineAuxiliaryValueMap', { required: true })
const defaultCurrency = unref(entityStore.entityDetail.defaultCurrency)

const cascaderAmountFCRefs: any = ref([])
const cascaderCurrencyInputRefs: any = ref([])
const formulaItem: any = ref({})
const formulaDialogRef = ref()
const showSearchValue = ref(false)
const originCounterpartyOptions: any = ref([])
const counterpartyOptions: any = ref([])
const currenciesData = ref<CurrencyItemType[]>([])
const currenciesCommonData = ref<CurrencyItemType[]>([])
const searchLoading = ref(false)
const searchQuery = ref('')
const marketActions = ['SPOT_BUY', 'SPOT_SELL', 'FOREIGN_EXCHANGE', 'SWAP']

const emit = defineEmits(['onChangeSelect', 'onChangeInternalChartOfAccount'])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const auxiliaryOptions = computed(() => {
  return (auxiliary: any) => {
    let list: any = []
    const auxiliaryType = find(props.auxiliaryTypeList, { auxiliaryTypeId: auxiliary.auxiliaryTypeId })
    if (props.isInternalTransfer) {
      if (auxiliary?.value?.type === 'ENTITY_ACCOUNT') {
        list = [
          {
            label: `OUTFLOW.ACCOUNT`,
            value: 'OUTFLOW'
          },
          {
            label: `INFLOW.ACCOUNT`,
            value: 'INFLOW'
          }
        ]
      } else if (auxiliary?.value?.type === 'AUXILIARY_ITEM') {
        auxiliaryType?.auxiliaryItems.forEach((item: any) => {
          list.push({
            label: item.value,
            value: item.auxiliaryItemId
          })
        })
      } else if (auxiliary?.value?.type === 'COUNTERPARTY') {
        list = [
          {
            label: `OUTFLOW.COUNTERPARTY `,
            value: 'OUTFLOW'
          },
          {
            label: `INFLOW.COUNTERPARTY `,
            value: 'INFLOW'
          }
        ]
      }
      return list
    }
    if (auxiliary.type === 'ENTITY_ACCOUNT') {
      if (props.derivativeType === 'CRYPTO_DISPOSAL') {
        list = [
          {
            label: `DISPOSAL.ACCOUNT`,
            value: 'DISPOSAL'
          },
          {
            label: `LOT.ACCOUNT`,
            value: 'LOT'
          }
        ]
      } else if (props.derivativeType === 'CRYPTO_ADJUSTING') {
        list = [
          {
            label: `${t('valuation.crypto.adjusting')}.${auxiliaryType?.name}`,
            value: 'ADJUSTING'
          }
        ]
      } else {
        list.push({
          label: `ADJUSTMENT.ACCOUNT`,
          value: 'ENTITY_ACCOUNT',
          info: `Treasury account for each adjustment`
        })
      }
      const accountList =
        props.model === 'edit'
          ? accountStore.accountList
          : accountStore.accountList.filter((item: any) => item.status === 'NORMAL')
      accountList.forEach((item: any) => {
        list.push({
          value: item.entityAccountId,
          label: item.name,
          icon: item.platform?.logo,
          alt: item.platform?.name,
          isDeleted: item.status !== 'NORMAL'
        })
      })
    }

    if (auxiliary.type === 'ITEM') {
      if (props.derivativeType === 'CRYPTO_DISPOSAL') {
        list = [
          {
            label: `DISPOSAL.${auxiliaryType?.name}`,
            value: 'DISPOSAL'
          },
          {
            label: `LOT.${auxiliaryType?.name}`,
            value: 'LOT'
          }
        ]
      } else if (props.derivativeType === 'CRYPTO_ADJUSTING') {
        list = [
          {
            label: `${t('valuation.crypto.adjusting')}.${auxiliaryType?.name}`,
            value: 'ADJUSTING'
          }
        ]
      }
      auxiliaryType?.auxiliaryItems.forEach((item: any) => {
        list.push({
          label: item.value,
          value: item.auxiliaryItemId
        })
      })
    }

    if (auxiliary.type === 'COUNTERPARTY') {
      if (props.derivativeType === 'CRYPTO_DISPOSAL') {
        list = [
          {
            label: `DISPOSAL.${auxiliaryType?.name}`,
            value: 'DISPOSAL'
          },
          {
            label: `LOT.${auxiliaryType?.name}`,
            value: 'LOT'
          }
        ]
      } else if (props.derivativeType === 'CRYPTO_ADJUSTING') {
        list = [
          {
            label: `${t('valuation.crypto.adjusting')}.${auxiliaryType?.name}`,
            value: 'ADJUSTING'
          }
        ]
      }
      counterpartyOptions.value.forEach((item: any) => {
        list.push({
          value: item.counterpartyId,
          label: item.name
        })
      })
    }
    list.push({
      label: 'Not Set',
      value: 'NONE'
    })
    return list
  }
})

const hasAmountTolerance = computed(() => {
  if (!props.conditionList?.length) return false
  const amountTolerance = props.conditionList?.find((i: any) => i.type === 'AMOUNT_TOLERANCE')
  if (!amountTolerance) return false
  return amountTolerance?.value > 0
})

const internalTransferAmountTolerance = computed(() => {
  return props.isInternalTransfer && hasAmountTolerance.value
})

const showInternalTransferAuxiliaryList = computed(() => {
  return (item: any) => {
    if (!props.isInternalTransfer) return true
    return item?.amountFC?.length > 0
  }
})

const chartOfAccountOptions = computed(() => {
  const filterList = entityStore.chartOfAccountOriginList.filter((i: any) => ['EXPENSE', 'OTHER'].includes(i.type))
  const otherOptions = filterList.find((item) => item.type === 'OTHER')
  if (otherOptions) {
    otherOptions.list = otherOptions.list.filter((otherItem) => otherItem.defaultBalanceType === 'Dr')
  }
  return filterList
})

const currentChartOfAccount: any = useComputedHook((chartOfAccountId: string) => {
  return find(entityStore.chartOfAccountList, { chartOfAccountId })
})

const isShowEntryCurrency = useComputedHook((index) => {
  if (props.isInternalTransfer) {
    return entryLineList.value[index]?.currency !== undefined && entryLineList.value[index]?.chartOfAccountId?.length
  }
  return entryLineList.value[index]?.currency !== undefined
})

const isShowEntryAmountFC = useComputedHook((index) => {
  if (props.isInternalTransfer) {
    return entryLineList.value[index]?.amountFC !== undefined && entryLineList.value[index]?.chartOfAccountId?.length
  }
  return entryLineList.value[index]?.amountFC !== undefined
})

const isShowEntryAuxiliaryList = useComputedHook((index) => {
  if (props.isInternalTransfer) {
    return entryLineList.value[index]?.auxiliaryTypes?.length && entryLineList.value[index]?.chartOfAccountId?.length
  }
  return entryLineList.value[index]?.auxiliaryTypes?.length
})

const currencyEnumOptions = computed(() => {
  if (props.derivativeType === 'VALIDATOR') {
    return [
      {
        label: 'Asset',
        value: 'MARGIN_ASSET_CURRENCY',
        title: 'Asset'
      }
    ]
  }
  return [
    {
      label: 'Margin_Asset',
      value: 'MARGIN_ASSET_CURRENCY',
      title: 'Margin Asset'
    }
  ]
})

const internalTransferAmountFcOptions = computed(() => {
  return [
    {
      label: '（OUTFLOW - INFLOW）*PRICE',
      value: 'DELTA',
      title: '（OUTFLOW - INFLOW）*PRICE'
    }
  ]
})

const amountOptions = computed(() => {
  if (props.derivativeType === 'CRYPTO_DISPOSAL') {
    return [
      {
        label: t('report.Quantity'),
        value: 'QUANTITY',
        title: t('report.Quantity')
      }
    ]
  }
  if (props.derivativeType === 'CRYPTO_ADJUSTING') {
    return []
  }
  return [
    {
      label: `${props.derivativeType === 'VALIDATOR' ? t('valuation.validator.rewardsChange') : t('valuation.future.unrealizedAndChange')}`,
      value: 'AMOUNT',
      title: `${props.derivativeType === 'VALIDATOR' ? t('valuation.validator.rewardsChange') : t('valuation.future.unrealizedAndChange')}`
    }
  ]
})

const isSaleJournalType = computed(() => {
  if (!props?.entryLines.length) return false
  const CrEntryList = props?.entryLines?.filter((i: any) => i?.balanceType === 'Cr')
  const CrMeetTheCriteria = CrEntryList?.some((entry: any) => {
    const chartOfAccount = currentChartOfAccount(entry?.chartOfAccountId).value
    if (['ASSET', 'LIABILITY'].includes(chartOfAccount?.type) && chartOfAccount?.currencyTypes?.[0] !== 'REPORTING') {
      return true
    }
    return false
  })
  return props.journalTypeData?.isBrokerInventory && CrMeetTheCriteria
})

const currencyOptions: any = useComputedHook((chartOfAccountId: string) => {
  const options: any = [
    {
      label: 'Disposal Currency',
      value: 'DISPOSAL_CURRENCY',
      type: 'DISPOSAL_CURRENCY'
    }
  ]
  const chartOfAccount = currentChartOfAccount(chartOfAccountId).value
  if (currenciesData.value.length || props.currencyList.length) {
    const children: any = [
      {
        label: 'Input',
        value: null,
        disabled: true,
        type: 'DIRECT_CURRENCY'
      }
    ]
    currenciesData.value.forEach((currency: CurrencyItemType) => {
      children.push({
        label: currency.showSymbol,
        value: currency.symbol,
        logo: currency.logo,
        alt: currency.name
      })
    })
    options.push({
      label: 'Currency',
      value: 'DIRECT_CURRENCY',
      children
    })
  }
  if (chartOfAccount?.currencyTypes?.[0] === 'REPORTING') {
    options.push({
      label: 'Currency',
      value: 'REPORTING_CURRENCY',
      children: [
        {
          label: entityStore.entityDetail.underlyingCurrency?.showSymbol,
          value: defaultCurrency,
          logo: entityStore.entityDetail.underlyingCurrency?.logo,
          alt: entityStore.entityDetail.underlyingCurrency?.name
        }
      ]
    })
  }
  return options
})

const amountParameters = computed(() => {
  return (index: string | number = '') => {
    let list = cloneDeep(props.amountFormulaList).filter((item: any) => item.type !== 'COST_BASIS_OF_SALES')
    if (index !== '') {
      const costBasisList = props.entryLines
        .filter((i: any) => i?.amountFC?.[0] === 'COST_BASIS_OF_SALES')
        .map((i: any) => i.index)
      list = list.filter(
        (item: any) =>
          (item.type === 'ENTRY_INDEX' &&
            item.value !== index &&
            (!costBasisList.includes(item.value) || props.entryLines?.[Number(index)].balanceType === 'Cr')) ||
          item.type !== 'ENTRY_INDEX'
      )
    }
    if (!marketActions.includes(props.conditionType)) {
      list = list.filter((item: any) => item.type !== 'BASE_AMOUNT_FC')
    }
    return list
  }
})

const amountFCOptions: any = computed(() => {
  return (i: number, balanceType: string) => {
    let options: any = []
    switch (props.derivativeType) {
      case 'CRYPTO_DISPOSAL':
        options = [
          {
            label: t('valuation.crypto.realizedGainLoss'),
            title: t('valuation.crypto.realizedGainLoss'),
            value: 'REALIZED_GAIN_LOSS'
          },
          {
            label: t('valuation.crypto.disposalAmount'),
            title: t('valuation.crypto.disposalAmount'),
            value: 'DISPOSAL_AMOUNT'
          },
          {
            label: t('valuation.crypto.costBasis'),
            title: t('valuation.crypto.costBasis'),
            value: 'COST_BASIS'
          }
        ]
        break
      case 'CRYPTO_ADJUSTING':
        options = [
          {
            label: t('valuation.crypto.unrealizedGainLoss'),
            title: t('valuation.crypto.unrealizedGainLoss'),
            value: 'UNREALIZED_GAIN_LOSS'
          }
        ]
        break
      default:
        options = [
          {
            label: `${props.derivativeType === 'VALIDATOR' ? t('valuation.validator.rewardsChange') : t('valuation.future.unrealizedAndChange')}`,
            title: `${props.derivativeType === 'VALIDATOR' ? t('valuation.validator.rewardsChange') : t('valuation.future.unrealizedAndChange')}`,
            value: 'AMOUNT'
          },
          {
            label: `${props.derivativeType === 'VALIDATOR' ? t('valuation.validator.rewardsChange') : t('valuation.future.unrealizedAndChange')} (${defaultCurrency})`,
            title: `${props.derivativeType === 'VALIDATOR' ? t('valuation.validator.rewardsChange') : t('valuation.future.unrealizedAndChange')} (${defaultCurrency})`,
            value: 'AMOUNT_FC'
          }
        ]
        break
    }

    props.entryLines.forEach((entryLine: any, index: number) => {
      if (index !== i) {
        const chartOfAccount = currentChartOfAccount(props.entryLines?.[i]?.chartOfAccountId).value
        const isOriginalCurrency =
          ['ASSET', 'LIABILITY'].includes(chartOfAccount?.type) && chartOfAccount?.currencyTypes?.[0] !== 'REPORTING'
        if (
          (isSaleJournalType.value && (entryLine?.amountFC?.[0] !== 'COST_BASIS_OF_SALES' || !isOriginalCurrency)) ||
          !isSaleJournalType.value
        ) {
          options.push({
            label: `#${index + 1} Amount(${defaultCurrency})`,
            title: `#${index + 1} Amount(${defaultCurrency})`,
            value: index
          })
        }
      }
    })

    if (props.journalTypeData?.isBrokerInventory && props.journalTypeData?.entryLines && balanceType === 'Cr') {
      const CrEntryList = props.journalTypeData?.entryLines?.filter((entry: any) => entry?.balanceType === 'Cr')
      const CrMeetTheCriteria = CrEntryList?.some((entry: any) => {
        const chartOfAccount = currentChartOfAccount(entry?.chartOfAccountId).value
        if (
          ['ASSET', 'LIABILITY'].includes(chartOfAccount?.type) &&
          chartOfAccount?.currencyTypes?.[0] !== 'REPORTING'
        ) {
          return true
        }
        return false
      })
      if (
        CrMeetTheCriteria &&
        currentChartOfAccount(props.journalTypeData?.entryLines?.[i]?.chartOfAccountId).value?.currencyTypes?.[0] !==
          'REPORTING'
      ) {
        options.push({
          label: `Cost Basis of Sales`,
          title: `Cost Basis of Sales`,
          value: 'COST_BASIS_OF_SALES'
        })
      }
    }

    options.push({
      label: `Formula`,
      value: 'FORMULA'
    })
    return options
  }
})

const hasGstTypeIndex = computed(() => {
  return entryLineList.value.findIndex((i: any) => i.gstType !== 'NONE' && i.gstType !== 'NA' && i.gstType !== '')
})

const currentCurrency: any = useComputedHook((symbol: string) => {
  return find(currenciesCommonData.value, { symbol })
})

const hasGstType = computed(() => {
  return entryLineList.value[hasGstTypeIndex.value]
})

const formulaFormatter = computed(() => {
  return (formula: string) => {
    const operators = ['+', '-', '*', '/', '(', ')']
    let list: { value: string; type: 'operator' | 'non-operator' | 'number' }[] = []
    let buffer = '' // 用于暂存连续的非操作符字符
    const formulaIsNumber = (char: string) => char >= '0' && char <= '9'
    for (let i = 0; i < formula.length; i += 1) {
      if (operators.includes(formula[i])) {
        if (buffer.length > 0) {
          const info = formulaIsNumber(buffer) ? 'number' : 'non-operator'
          list.push({ value: buffer, type: info }) // 将暂存的非操作符字符作为一个整体推入数组
          buffer = '' // 重置 buffer
        }
        list.push({ value: formula[i], type: 'operator' }) // 如果当前字符是操作符，直接推入数组
      } else {
        buffer += formula[i] // 将非操作符字符添加到 buffer
      }
    }

    if (buffer.length > 0) {
      // 修改：循环结束后，使用 isNumber 判断 buffer 是否为数字
      const info = formulaIsNumber(buffer) ? 'number' : 'non-operator'
      list.push({ value: buffer, type: info }) // 将最后的非操作符字符作为一个整体推入数组
    }
    list = list.map((item) => {
      if (item.type === 'non-operator') {
        const parameter = props.amountFormulaList.find((val: any) => val.var === item.value)
        return { ...item, value: parameter ? parameter.label : item.value }
      }
      return item
    })
    return list
  }
})

const onChangeInternalChartOfAccount = () => {
  emit('onChangeInternalChartOfAccount')
}

const remoteCounterpartyMethod = async (query: string) => {
  if (query) {
    try {
      searchQuery.value = query
      searchLoading.value = true
      const params = {
        keywords: query,
        limit: 20,
        page: 1
      }
      const { data } = await AccountsApi.getCounterpartyList(entityId.value, params)
      counterpartyOptions.value = data.list.filter((item: any) => item.type !== 'FEE')
    } catch (error) {
      console.log(error)
    } finally {
      searchLoading.value = false
    }
  } else {
    searchQuery.value = ''
    counterpartyOptions.value = originCounterpartyOptions.value
  }
}

// 搜索Currency
const remoteCurrencyMethod = async (index: number) => {
  const dataIndex = index < 0 ? 0 : index
  const chartOfAccount = currentChartOfAccount(entryLineList.value?.[dataIndex]?.chartOfAccountId ?? '').value
  const isTreasuryAccount = !chartOfAccount?.chartOfAccountId
  if (entryLineList.value?.[dataIndex]?.chartOfAccountId === '' && isTreasuryAccount) return

  if (searchQuery.value) {
    try {
      searchLoading.value = true
      const currencyTypes = chartOfAccount?.currencyTypes ?? []
      const params = {
        recommend: false,
        keywords: [searchQuery.value],
        entityId: entityId.value,
        type: !isTreasuryAccount ? [] : currencyTypes
      }
      const { data } = await ConfigApi.searchCurrencyList(params)
      currenciesData.value = data
      const list = [...cloneDeep(currenciesCommonData.value), ...data]
      currenciesCommonData.value = uniqBy(list, 'symbol')
      showSearchValue.value = true
      setTimeout(() => {
        cascaderCurrencyInputRefs.value?.[index]?.focus()
      }, 300)
    } catch (error) {
      console.log(error)
      currenciesData.value = []
      showSearchValue.value = false
    } finally {
      searchLoading.value = false
    }
  } else {
    showSearchValue.value = false
    currenciesData.value = props.currencyList
    setTimeout(() => {
      cascaderCurrencyInputRefs.value?.[index]?.focus()
    }, 300)
  }
}

const onOpenFormula = (key: string, index: number, data: any) => {
  if (data?.value === 'FORMULA') {
    if (key === 'amountFC') {
      cascaderAmountFCRefs.value?.[index]?.togglePopperVisible()
    }
    formulaItem.value.key = key
    formulaItem.value.index = index
    formulaItem.value.value =
      entryLineList.value?.[index]?.[key]?.length > 1 && !isNumber(entryLineList.value?.[index]?.[key]?.[1])
        ? entryLineList.value?.[index]?.[key]?.[1] || ''
        : ''
    formulaDialogRef.value?.onCheckFormulaDialog()
  }
}

const onChangeSelect = (key: string, index: number, value: any) => {
  let data: any = value
  console.log(value)
  if (key === 'amountFC' && data === undefined) {
    data = ['FORMULA', entryLineList.value?.[index]?.amountFC?.[1] ?? '']
  }
  emit('onChangeSelect', key, index, data)
}

const onSaveFormula = (formula: string) => {
  if (formulaItem.value) {
    emit('onChangeSelect', formulaItem.value.key, formulaItem.value.index, ['FORMULA', formula])
  }
}

defineExpose({ formulaFormatter })

watch(
  () => accountStore.counterpartList.list,
  () => {
    if (!accountStore.counterpartList.list.length) return
    const list = [...cloneDeep(accountStore.counterpartList.list)]
    counterpartyOptions.value = uniqBy(list, 'counterpartyId')
    originCounterpartyOptions.value = uniqBy(list, 'counterpartyId')
  },
  { immediate: true }
)

watch(
  () => props.currencyList,
  () => {
    if (!props.currencyList.length) return
    currenciesData.value = props.currencyList
    const list = [...cloneDeep(props.currencyList)]
    if (entityStore.entityDetail?.underlyingCurrency) {
      list.push(entityStore.entityDetail.underlyingCurrency)
    }
    currenciesCommonData.value = uniqBy(list, 'symbol')
  },
  { immediate: true }
)
</script>

<style lang="scss">
.elv-automation-rule-action-entry {
  width: 100%;
  max-width: 802px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .elv-automation-rule-entry-item {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #f9fafb;

    &.is-Cr {
      background: #fffaf9;
    }

    &.is-Dr {
      background: #f5f8ff;
    }

    .elv-automation-rule-entry-reporting-currency {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 50%;
        margin-right: 10px;
      }

      p {
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
      }
    }

    .elv-automation-rule-entry-title {
      display: flex;
      align-items: center;
      color: #0e0f11;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      &.is-disabled {
        color: #0e0f1166;
      }

      span {
        padding: 2px 4px;
        font-family: 'Barlow';
        font-weight: 500;
        border-radius: 2px;
        background: #feede9;
        color: #f24923;
        margin-right: 10px;

        &.is-Dr {
          background: #e2eafc;
          color: #2368f2;
        }
      }

      p {
        display: flex;
        min-width: 20px;
        height: 21px;
        padding: 2px 4px;
        box-sizing: border-box;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 2px;
        background: #edf0f3;
        color: #1e2024;
        font-family: 'Barlow';
        font-weight: 500;
        margin-right: 10px;
      }

      .elv-automation-rule-entry-item-chart-of-account-cascade {
        .elv-base-cascade-select-show-info-container-single-choice {
          font-weight: 400;
        }
      }
    }

    .elv-automation-rule-entry-auxiliary-list {
      width: 100%;
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid #edf0f3;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 8px;

      .elv-automation-rule-entry-auxiliary-item {
        width: 256.66px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 8px;

        .elv-automation-rule-entry-auxiliary-item__title {
          color: #636b75;
          font-family: 'Plus Jakarta Sans';
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .elv-automation-rule-entry-item-cascader {
          position: relative;
          height: fit-content;

          .el-cascader.el-select .el-input {
            width: 256.66px;
            height: 44px;
          }

          .elv-automation-rule-entry-item-cascader__display {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 12px;
            width: 208px;
            height: 30px;
            background: #fff;
            z-index: 10;
            pointer-events: none;

            .is-placeholder {
              color: #838d95;
              font-family: 'Plus Jakarta Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;
            }

            .cascader-placeholder__with-icon {
              display: flex;
              align-items: center;
              color: #0e0f11;
              font-family: 'Plus Jakarta Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;
              max-width: 208px;
              white-space: nowrap;
              overflow: hidden;

              img {
                width: 20px;
                height: 20px;
                display: block;
                border-radius: 50%;
                margin-right: 10px;
              }

              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                min-width: 0; /* 确保flex容器内文本不撑破容器 */
                flex-shrink: 1; /* 允许文本缩小以适应容器 */
              }
            }

            .is-formula {
              display: flex;
              align-items: center;

              svg {
                margin-right: 2px;
              }

              .is-operator {
                color: #1e2024;
                font-family: 'Barlow';
                font-size: 13px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0 6px;
              }
            }

            .cascader-placeholder__tag {
              display: inline-block;
              width: fit-content;
              padding: 0px 8px;
              border-radius: 3px;
              border: 1px solid #dde1e6;
              background: #edf0f3;
              color: #1753eb;
              font-family: 'Barlow';
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 28px;
              max-width: 208px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            &.is-disabled {
              background-color: #f9fafb;
            }
          }
        }

        .el-select__selected-item.el-select__placeholder:not(.is-transparent) {
          span {
            color: #1753eb;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 28px;
            display: flex;
            padding: 0px 8px;
            justify-content: center;
            align-items: center;
            gap: 2px;
            border-radius: 3px;
            border: 1px solid #dde1e6;
            background: #edf0f3;
            width: fit-content;
          }
        }
      }
    }
  }
}

.el-popper.el-select__popper.elv-automation-rule-entry-auxiliary-item-popper {
  &:not(.is-not__line) {
    .el-select-dropdown__item {
      &:last-of-type {
        border-top: 1px solid #edf0f3;
      }

      &:nth-of-type(2) {
        border-top: 1px solid #edf0f3;

        &:has(.elv-automation-auxiliary-dropdown__item-special) {
          border-top: 0px;
        }
      }
    }
  }

  .el-select-dropdown__item {
    min-height: 32px;
    height: fit-content;

    .elv-automation-auxiliary-dropdown__item-special {
      display: flex;
      flex-direction: column;
      padding: 8px 0;

      > div {
        display: flex;
        padding: 0px 8px;
        width: fit-content;
        justify-content: center;
        align-items: center;
        gap: 2px;
        border-radius: 3px;
        border: 1px solid #dde1e6;
        background: #edf0f3;
        color: #1753eb;
        font-family: 'Barlow';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 4px;
      }

      p {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .is-deleted {
      display: flex;
      height: 19px;
      padding: 6px 8px;
      align-items: center;
      box-sizing: border-box;
      color: #fb212f;
      leading-trim: both;
      text-edge: cap;
      font-family: 'Barlow';
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-radius: 14px;
      background: #edf0f3;
      margin-left: 8px;
    }
  }
}

.el-popper.elv-automation-rule-entry-item-cascader-popper {
  transform: translateY(-11.5px);
  box-shadow: none;
  background-color: transparent;
  border: 0px;

  .el-cascader-panel {
    padding: 1px;
    border-radius: 6px;
  }

  .el-scrollbar.el-cascader-menu {
    border-radius: 6px;
    background: #fff;

    box-shadow:
      0px 2px 6px 0px rgba(0, 0, 0, 0.05),
      0px 0px 1px 0px rgba(0, 0, 0, 0.3);
    height: fit-content;
    position: relative;

    &:first-of-type {
      .el-cascader-menu__wrap.el-scrollbar__wrap {
        height: fit-content;
      }
    }

    &:last-of-type {
      z-index: 10;
    }
  }

  &.is-filterable {
    .el-scrollbar.el-cascader-menu:nth-of-type(2) .el-cascader-menu__list {
      padding-top: 41px;
    }
  }

  .el-cascader-menu__list {
    padding: 0px;
    border-radius: 6px;
    height: fit-content;
    position: relative;

    .el-cascader-node {
      display: flex;
      min-height: 32px;
      height: fit-content;
      box-sizing: border-box;
      align-items: center;
      gap: 8px;
      padding: 0px;
      align-self: stretch;
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.is-disabled {
        position: fixed;
        top: 0px;
        background-color: #fff;
        z-index: 10;
      }

      &.in-active-path {
        background-color: #f9fafb;
      }

      .el-cascader-node__label {
        display: flex;
        align-items: center;
        padding: 0px;
        width: 100%;
        min-height: 32px;
        height: fit-content;

        img {
          width: 18px;
          height: 18px;
          display: block;
          border-radius: 50%;
          margin-right: 8px;
        }

        .elv-automation-rule-entry-item-cascader-item {
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 0px 8px;
          display: flex;
          align-items: center;

          &.is-formula {
            width: 100%;
            height: 32px;
            border-top: 1px solid #edf0f3;
            color: #1753eb;
          }

          &.has-line {
            width: 100%;
            height: 32px;
            border-bottom: 1px solid #edf0f3;
          }

          p {
            display: flex;
            padding: 6px 8px;
            height: 19px;
            box-sizing: border-box;
            align-items: center;
            color: #fb212f;
            leading-trim: both;
            text-edge: cap;
            font-family: 'Barlow';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            border-radius: 14px;
            background: #edf0f3;
            margin-left: 8px;
          }
        }

        .elv-automation-rule-entry-item-cascader-search.el-input {
          height: 40px;
          border-bottom: 1px solid #edf0f3;

          .el-input__wrapper {
            padding: 1px 8px;
            border: none !important;
            box-shadow: none !important;
          }
        }
      }

      &.is-active {
        color: #1753eb;
      }
    }

    .el-icon.el-cascader-node__prefix {
      display: none;
    }
  }
}
</style>
