<template>
  <div class="elv-automation-table-row__cell-action">
    <p class="elv-automation-table-row__cell-action__title">{{ t('title.createJournalCell') }}:</p>
    <div class="elv-automation-table-cell-action__item">
      <span class="elv-automation-table-row__cell-action__label">{{ t('report.journalType') }} is</span>
      <span class="elv-automation-table-row__cell-action__value">{{ props.params?.data.journalType?.name }}</span>
    </div>
    <div
      v-if="props.activeTab !== 'transfer' && props.params?.data?.timezone"
      class="elv-automation-table-cell-action__item"
    >
      <span class="elv-automation-table-row__cell-action__label">{{ t('common.timezone') }} =</span>
      <span class="elv-automation-table-row__cell-action__value"
        >“{{ timezoneData?.area }}({{ timezoneData?.timezone }})”</span
      >
    </div>
    <div
      v-if="props.activeTab !== 'transfer' && props.params?.data?.datetime"
      class="elv-automation-table-cell-action__item"
    >
      <span class="elv-automation-table-row__cell-action__label">{{ t('common.date') }} =</span>
      <span class="elv-automation-table-row__cell-action__value">{{
        props.activeTab === 'transfer'
          ? 'Datetime'
          : props.params?.data.businessDataType?.columns?.[props.params?.data.datetime]
      }}</span>
    </div>
    <div
      v-if="
        (props.activeTab !== 'transfer' &&
          (props.params?.data?.referenceNo || props.params?.data?.referenceNo !== '')) ||
        props.activeTab === 'transfer'
      "
      class="elv-automation-table-cell-action__item"
    >
      <span class="elv-automation-table-row__cell-action__label">{{ t('title.refNo') }} =</span>
      <span class="elv-automation-table-row__cell-action__value">{{ referenceNoValue }}</span>
    </div>

    <div
      v-if="props.activeTab !== 'transfer' && props.params?.data?.additionalItem?.length"
      class="elv-automation-table-cell-action__item"
    >
      <span class="elv-automation-table-row__cell-action__label">{{ t('title.additionalItem') }} =</span>
      <span class="elv-automation-table-row__cell-action__value"
        >[<span v-for="(item, index) in props.params?.data?.additionalItem" :key="index"
          ><template v-if="index > 0">,</template>{{ props.params?.data.businessDataType?.columns?.[item] }}</span
        >]</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { find } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import timezoneList from '@/config/timezone'

const props = defineProps({
  activeTab: {
    type: String,
    default: ''
  },
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})
const { t } = useI18n()

const timezoneData: any = computed(() => {
  if (props.activeTab === 'transfer') return {}
  return find(timezoneList, { area: props.params?.data?.timezone }) ?? {}
})

const referenceNoValue = computed(() => {
  switch (props.activeTab) {
    case 'transfer':
      return 'Transfer Id'
    case 'trade':
      return 'Trade Id'
    case 'gainLoss':
      return 'Gain (Loss) Id'
    case 'derivativeAdjustment':
      return props.params?.data?.derivativeType === 'CRYPTO_DISPOSAL' ? 'Disposal No.' : 'Adjusting Id'
    default:
      return props.params?.data.businessDataType?.columns?.[props.params?.data.referenceNo]
  }
})
</script>

<style lang="scss" scoped></style>
