<template>
  <div class="elv-automation-table-row__cell-action">
    <p class="elv-automation-table-row__cell-action__title">
      {{ props.params?.data.balanceType }}:&nbsp;
      <template v-if="props.params?.data?.chartOfAccountCalculator === 'TREASURY_ACCOUNT'"
        >{{ `{ ${t('report.treasuryAccount')} }` }}:</template
      >
      <span v-else-if="props.params?.data?.chartOfAccountCalculator === 'ADJUSTING'">
        {{ t('valuation.crypto.adjustingAccounts') }}
      </span>
      <template v-else>{{ props.params?.data?.chartOfAccount?.name }}:</template>
    </p>
    <div
      v-for="item in props.params?.data?.auxiliaryMapping"
      :key="item.auxiliaryCodeId"
      class="elv-automation-table-cell-action__item"
    >
      <span class="elv-automation-table-row__cell-action__label"
        >{{ t('report.auxiliaryCode') }}.{{ item.auxiliaryType?.name }} =</span
      >
      <span class="elv-automation-table-row__cell-action__value">{{ auxiliaryCodeValue(item) }}</span>
    </div>
    <div class="elv-automation-table-cell-action__item">
      <span class="elv-automation-table-row__cell-action__label">{{ t('common.currency') }} =</span>
      <span class="elv-automation-table-row__cell-action__value">{{ currencyValue }}</span>
    </div>
    <div v-if="props.params?.data?.amount" class="elv-automation-table-cell-action__item">
      <span class="elv-automation-table-row__cell-action__label">{{ t('report.originalAmount') }} =</span>
      <span class="elv-automation-table-row__cell-action__value">{{ originAmountValue }}</span>
    </div>
    <div
      v-if="
        props.activeTab !== 'transfer' &&
        (props.params?.data?.amountFC || currencyValue === 'Reporting Currency' || !isEmpty(hasGstType))
      "
      class="elv-automation-table-cell-action__item"
    >
      <span class="elv-automation-table-row__cell-action__label">{{ t('report.reportingAmount') }} =</span>
      <span class="elv-automation-table-row__cell-action__value">{{ reportingAmountValue }}</span>
    </div>

    <div v-if="props.entryIndex === hasGstTypeIndex" class="elv-automation-table-row__cell-action__label">
      <span
        >{{ t('title.GSTRate') }}: {{ transformI18n(find(journalTypeGstOptions, { value: hasGstType?.gstType })?.label)
        }}<span v-if="hasGstType?.gstType !== 'EXEMPT'"
          >, {{ hasGstType?.gstType !== 'ZERO' ? `${Number(hasGstType?.gstRatio)}%` : '0%' }}</span
        ></span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { transformI18n } from '@/i18n/index'
import { gstFormulaFormat } from '@/lib/utils'
import { useEntityStore } from '@/stores/modules/entity'
import { useComputedHook } from '@/hooks/useComputedHook'
import { find, isEmpty, every, capitalize } from 'lodash-es'
import { journalTypeGstOptions } from '@/config/reports/index'

const props = defineProps({
  activeTab: {
    type: String,
    default: ''
  },
  entryIndex: {
    type: Number,
    default: 0
  },
  params: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const { t } = useI18n()

const entityStore = useEntityStore()
const defaultCurrency = unref(entityStore.entityDetail.defaultCurrency)

const currentChartOfAccount: any = useComputedHook((chartOfAccountId: string) => {
  return find(entityStore.chartOfAccountList, { chartOfAccountId })
})

const hasGstTypeIndex = computed(() => {
  return props.params.data?.journalType?.entryLines.findIndex(
    (i: any) => i.gstType !== 'NONE' && i.gstType !== 'NA' && i.gstType !== ''
  )
})

const hasGstType = computed(() => {
  return props.params.data?.journalType.entryLines[hasGstTypeIndex.value]
})

const currencyValue = computed(() => {
  let value = ''
  if (
    props.activeTab === 'derivativeAdjustment' &&
    !['CRYPTO_DISPOSAL', 'CRYPTO_ADJUSTING'].includes(props.params?.data?.derivativeType)
  ) {
    return props.params?.data?.derivativeType === 'VALIDATOR' ? 'Asset' : 'Margin_Asset'
  }
  if (
    props.activeTab !== 'businessData' &&
    !['CRYPTO_DISPOSAL', 'CRYPTO_ADJUSTING'].includes(props.params?.data?.derivativeType)
  ) {
    const chartOfAccount = currentChartOfAccount(props.params?.data.chartOfAccountId).value
    return chartOfAccount?.currencyTypes?.[0] === 'REPORTING' ||
      ['gst_input', 'gst_output'].includes(chartOfAccount?.slug)
      ? defaultCurrency
      : capitalize(props.params?.data?.currency)
  }
  switch (props.params?.data?.currencyType) {
    case 'TRANSACTION_CURRENCY':
      value = 'Currency'
      break
    case 'REPORTING_CURRENCY':
      value = 'Reporting Currency'
      break
    case 'DISPOSAL_CURRENCY':
      value = t('valuation.crypto.disposalCurrency')
      break
    case 'ADJUSTING_CURRENCY':
      value = t('valuation.crypto.adjustingCurrency')
      break
    case 'DIRECT_CURRENCY':
      value = `“${props.params?.data.directUnderlyingCurrency?.showSymbol ?? props.params?.data.directCurrency ?? props.params?.data.underlyingCurrency?.showSymbol}”`
      break
    default:
      value = props.params?.data?.businessDataType?.columns?.[props.params?.data?.businessCurrency]
      break
  }
  return value
})

const originAmountValue = computed(() => {
  if (props.activeTab === 'derivativeAdjustment') {
    if (props.params?.data?.derivativeType === 'CRYPTO_DISPOSAL') {
      return t('report.Quantity')
    }
    return props.params?.data?.derivativeType === 'VALIDATOR'
      ? t('valuation.validator.rewardsChange')
      : t('valuation.future.unrealizedAndChange')
  }
  if (props.activeTab !== 'businessData') {
    return capitalize(props.params?.data?.amount)
  }
  const formula = props.params?.data?.formulaFieldMap.reduce((acc: any, cur: any) => {
    const reg = new RegExp(cur.var, 'g')
    switch (cur.type) {
      case 'CURRENCY_PRICE':
        return acc.replace(reg, '{Exchange rate}')
      case 'CURRENCY_FIAT_PRICE':
        return acc.replace(reg, 'Fiat Exchange Rate')
      case 'BUSINESS_FIELD':
        return acc.replace(reg, props.params?.data?.businessDataType?.columns?.[cur.value])
      case 'CURRENCY_CROPTY_PRICE':
      case 'CURRENCY_CRYPTO_PRICE':
        return acc.replace(reg, 'Crypto Exchange Rate')
      case 'TRANSACTION_AMOUNT':
        return acc.replace(reg, 'Amount')
      default:
        return acc.replace(reg, cur.value)
    }
  }, props.params?.data?.amount)
  return formula
})

const reportingAmountValue = computed(() => {
  const formula = props.params?.data?.formulaFieldMap.reduce((acc: any, cur: any) => {
    const reg = new RegExp(cur.var, 'g')
    switch (cur.type) {
      case 'COST_BASIS_OF_SALES':
        return acc.replace(reg, 'Broker inventory.Sales.Cost Basis')
      case 'CURRENCY_PRICE':
        return acc.replace(reg, '{Exchange rate}')
      case 'TRANSACTION_AMOUNT':
        return acc.replace(reg, 'Amount')
      case 'CURRENCY_FIAT_PRICE':
        return acc.replace(reg, 'Fiat Exchange Rate')
      case 'DISPOSAL_AMOUNT':
        return acc.replace(reg, t('valuation.crypto.disposalAmount'))
      case 'COST_BASIS':
        return acc.replace(reg, t('valuation.crypto.costBasis'))
      case 'REALIZED_GAIN_LOSS':
        return acc.replace(reg, t('valuation.crypto.realizedGainLoss'))
      case 'UNREALIZED_GAIN_LOSS':
        return acc.replace(reg, t('valuation.crypto.unrealizedGainLoss'))
      case 'BUSINESS_FIELD':
        return acc.replace(reg, props.params?.data?.businessDataType?.columns?.[cur.value])
      case 'BUSSINESS_DATA_FIELD':
        return acc.replace(reg, cur.value)
      case 'BASE_AMOUNT_FC':
        return acc.replace(reg, `Base amount(${defaultCurrency})`)
      case 'COUNTER_AMOUNT_FC':
        return acc.replace(reg, `Counter amount(${defaultCurrency})`)
      case 'FEE_AMOUNT_FC':
        return acc.replace(reg, `Fee amount(${defaultCurrency})`)
      case 'AMOUNT':
        if (props.activeTab === 'derivativeAdjustment') {
          return acc.replace(
            reg,
            props.params?.data?.derivativeType === 'VALIDATOR'
              ? t('valuation.validator.rewardsChange')
              : t('valuation.future.unrealizedAndChange')
          )
        }
        return acc.replace(reg, 'Amount')
      case 'AMOUNT_FC':
        if (props.activeTab === 'derivativeAdjustment') {
          return acc.replace(
            reg,
            `${
              props.params?.data?.derivativeType === 'VALIDATOR'
                ? t('valuation.validator.rewardsChange')
                : t('valuation.future.unrealizedAndChange')
            }(${defaultCurrency})`
          )
        }
        return acc.replace(reg, `Amount(${defaultCurrency})`)
      case 'ENTRY_INDEX':
        const otherChartOfAccount = props.params?.data?.entryActions[cur.value]?.chartOfAccount
        return acc.replace(reg, `${otherChartOfAccount?.name ?? `{ ${t('report.treasuryAccount')} }`}.Reporting Amount`)
      default:
        return acc.replace(reg, 'Crypto Exchange Rate')
    }
  }, props.params?.data?.amountFC)

  if (formula === '' && hasGstType.value && props.entryIndex !== hasGstTypeIndex.value) {
    const chartOfAccount = currentChartOfAccount(props.params?.data.chartOfAccountId).value
    return gstFormulaFormat(
      hasGstType.value?.gstType,
      hasGstTypeIndex.value,
      ['gst_input', 'gst_output'].includes(chartOfAccount?.slug),
      hasGstType.value?.gstRatio,
      defaultCurrency
    )
  }
  if (formula === '' && props.params?.data?.currencyType === 'REPORTING_CURRENCY') {
    const otherChartOfAccount = find(props.params?.data?.entryActions, (item: any) => {
      return props.params?.data?.chartOfAccountId && props.params?.data?.chartOfAccountId !== item.chartOfAccountId
    })?.chartOfAccount

    if (
      every(
        props.params?.data?.entryActions,
        (value: any) => value.chartOfAccountId === props.params?.data?.chartOfAccountId
      )
    ) {
      return `${props.params?.data?.chartOfAccount?.name}.Reporting Amount`
    }
    return otherChartOfAccount?.name
      ? `${otherChartOfAccount.name}.Reporting Amount`
      : `{ ${t('report.treasuryAccount')} }.Reporting Amount`
  }

  return formula
})

const auxiliaryCodeValue = computed(() => {
  return (item: any) => {
    let value = ''
    if (props.activeTab === 'businessData') {
      switch (item.type) {
        case 'TO_ADDRESS':
          value = 'To'
          break
        case 'FROM_ADDRESS':
          value = 'From'
          break
        case 'ENTITY_ACCOUNT_ID':
          value = t('common.account')
          break
        case 'CONTACT_IDENTITY':
          value = t('common.counterparty')
          break
        case 'CONSTANT':
          value = `“${item.value}”`
          break
        case 'DIRECT':
          if (item.auxiliaryCode === 'ENTITY_ACCOUNT') {
            value = `“${item?.entityAccount?.name ? item?.entityAccount?.name : item?.value?.entityAccount?.name}”`
          }
          if (item.auxiliaryCode === 'COUNTERPARTY') {
            value = `“${item?.counterparty?.name ? item?.counterparty?.name : item?.value?.counterparty?.name}”`
          }
          if (item.auxiliaryCode === 'ITEM') {
            value = `“${item?.auxiliaryItem?.value ? item?.auxiliaryItem?.value : item?.value?.auxiliaryItem?.value}”`
          }
          break
        case 'PATH':
          value =
            item.value?.columnIndex !== ''
              ? props.params?.data?.businessDataType?.columns?.[item.value?.columnIndex]
              : props.params?.data?.businessDataType?.columns?.[item.value]
          break
        default:
          value = item.value
          break
      }
      return value
    }

    let fromEnum = 'TRANSFER'
    if (props.activeTab === 'gainLoss') {
      fromEnum = 'GAIN (LOSS)'
    } else if (props.activeTab === 'trade') {
      fromEnum = 'TRADE'
    } else if (props.activeTab === 'derivativeAdjustment') {
      fromEnum = 'ADJUSTMENT'
    }

    switch (item?.value?.type) {
      case 'ENTITY_ACCOUNT':
        if (item?.value?.entitySource) {
          value = `${item?.value?.entitySource}.ACCOUNT`
        } else if (item?.value?.entityAccountId) {
          value = `“${item?.entityAccount?.name ? item?.entityAccount?.name : item?.value?.entityAccount?.name}”`
        } else {
          value = `${fromEnum}.ACCOUNT`
        }
        break
      case 'AUXILIARY_ITEM':
        if (item?.value?.auxiliaryItemSource) {
          value = `${item?.value?.auxiliaryItemSource}.${item?.auxiliaryType?.name}`
        } else {
          value = item?.value?.auxiliaryItemId
            ? item?.value?.auxiliaryItem?.value
            : `${fromEnum}.ACCOUNT.${item?.auxiliaryType?.name}`
        }
        break
      case 'COUNTERPARTY':
        if (item?.value?.counterpartySource) {
          value = `${item?.value?.counterpartySource}.${item?.auxiliaryType?.name}`
        } else if (item?.value?.counterpartyId) {
          value = `“${item?.counterparty?.name ? item?.counterparty?.name : item?.value?.counterparty?.name}”`
        } else {
          value = `${fromEnum}.ACCOUNT.COUNTERPARTY`
        }
        break
      case 'ENTITY_ACCOUNT_COUNTERPARTY':
        value = `${fromEnum}.ACCOUNT.${item?.auxiliaryType?.name}`
        break
      case 'NONE':
        value = `None`
        break
      default:
        break
    }

    return value
  }
})
</script>

<style lang="scss" scoped></style>
